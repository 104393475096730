<template>
  <BModal
    id="verifyCompany"
    centered
    :ok-title="'Сохранить' + (sec !== 0 ? ` (${sec})` : '')"
    cancel-title="Отмена"
    cancel-variant="danger"
    title="Подтверждение взыскателя"
    no-close-on-backdrop
    :ok-disabled="sec !== 0"
    class="list-items-views-edit"
    @ok.prevent="okClicked"
    @show="onShowModal"
  >
    <div class="w-100 text-center">
      <h4>{{ company }}</h4>
    </div>
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    company: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sec: 3,
      timer: null,
    }
  },
  methods: {
    async okClicked() {
      this.$emit('okClicked')
    },
    init() {
      this.sec = 3
      this.timer = setInterval(() => {
        if (this.sec !== 0) {
          this.sec -= 1
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    onShowModal() {
      this.init()
    },
  },
}
</script>
