<template>
  <BCard>
    <b-row class="mt-1">
      <b-col class="col-md-2">
        <b-form-input
          v-model="filter.search"
          placeholder="Поиск..."
          type="search"
          @input="searchConstructors"
        />
      </b-col>

      <b-col class="col-md-2">
        <VcDatePickerVue
          v-model="dateFilter"
          placeholder="Дата (с-по)"
          :other-props="{ 'is-range': true }"
          @input="datePicker"
        />
      </b-col>

      <b-col class="col-md-4 ml-auto text-right">
        <BButton
          variant="success"
          @click="exportExcel"
        >
          <feather-icon icon="DownloadIcon" />
          Export.xls
        </BButton>

        <BButton
          class="ml-1"
          variant="primary"
          @click="openModal"
        >
          <feather-icon icon="DownloadIcon" />
          Импорт
        </BButton>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <BTable
        id="user-table"
        class="bg-light-secondary px-1 rounded mtd-1 my-2"
        :tbody-tr-class="['tableRowClass']"
        :items="logs.results"
        :fields="fields"
        hover
        small
        responsive
        show-empty
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(author)="props">
          <div class="d-flex flex-column">
            <small class="font-weight-bold">{{ props.item.author }}</small>
            <!--            <span class="font-weight-bold">{{ props.item.file_name }}</span>-->
          </div>
        </template>
        <template #cell(status)="{ value }">
          <b-badge :variant="status[value]">
            {{ value }}
          </b-badge>
        </template>
        <template #cell(file)="data">
          <b-button
            class="p-50"
            size="sm"
            variant="primary"
            @click="downloadFile(data.item.file)"
          >
            <FeatherIcon icon="FileTextIcon" />
          </b-button>
        </template>
        <template #cell(type)="{ value }">
          {{ types[value] }}
        </template>
        <template #cell(created_date)="{ value }">
          <b>{{ value.split(' ')[0] }}</b> <br>
          {{ value.split(' ')[1] }}
        </template>
      </BTable>
    </b-overlay>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap mr-1">Показать по</span>
        <b-form-select
          v-model="filter.page_size"
          :options="['5', '10', '20']"
          class="mx-1"
          @change="fetchActionList"
        />
        <span class="ml-1 text-nowrap"> строк( {{ logs.count }} )</span>
      </div>
      <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="logs.count"
          :per-page="filter.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <importModal
      @refresh="fetchActionList"
    />
  </BCard>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BOverlay,
  BRow,
  BCol,
  BBadge,
  BFormInput, BPagination, BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'
import VcDatePickerVue from '@/components/VcDatePicker.vue'
import importModal from '../components/importModal.vue'

export default {
  components: {
    BFormSelect,
    BPagination,
    BCard,
    BButton,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    empty,
    importModal,
    VcDatePickerVue,
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        page_size: 10,
        search: null,
      },
      dateFilter: {
        created_date_from: null,
        created_date_to: null,
      },
      types: {
        DEBT: 'Дела',
        PAYMENT: 'Платежи',
      },
      editMod: {},
      count: 2,
      status: {
        FAILED: 'danger',
        IN_PROGRESS: 'warning',
        SUCCESS: 'success',
      },
      fields: [
        {
          key: 'id',
          label: '№',
          thClass: 'tableHeadClass',
        },
        {
          key: 'author',
          label: 'Автор',
          thClass: 'tableHeadClass',
        },
        {
          key: 'file_name',
          label: 'Имя файла',
          thClass: 'tableHeadClass',
        },
        {
          key: 'status',
          label: 'Статус',
          thClass: 'tableHeadClass',
        },
        {
          key: 'description',
          label: 'Описание',
          thClass: 'tableHeadClass',
        },
        {
          key: 'created_date',
          label: 'Дата Создание',
          thClass: 'tableHeadClass',
        },
        {
          key: 'type',
          label: 'Тип',
          thClass: 'tableHeadClass',
        },
        {
          key: 'file',
          label: 'Файл',
          thClass: 'tableHeadClass',
          formatter: value => value.split('/')
            .at(-1),
        },
      ],
    }
  },
  computed: {
    ...mapState('importRegistries', ['logs']),
  },
  mounted() {
    this.fetchActionList()
  },
  methods: {
    ...mapActions('importRegistries', ['FETCH_UPLOADS_LOGS', 'FETCH_EXCEL']),

    exportExcel() {
      this.FETCH_EXCEL({ ...this.filter, ...this.dateFilter })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'file.xls')
          document.body.appendChild(link)
          link.click()
          this.$_okToast()
        })
    },

    async datePicker(e) {
      this.filter.page = 1
      if (e) {
        this.dateFilter.created_date_from = e.start
        this.dateFilter.created_date_to = e.end
        await this.FETCH_UPLOADS_LOGS({
          created_date_from: this.dateFilter.created_date_from ? this.dateFilter.created_date_from : null,
          created_date_to: this.dateFilter.created_date_to ? this.dateFilter.created_date_to : null,
          ...this.filter,
        })
      } else {
        this.dateFilter = {
          created_date_from: null,
          created_date_to: null,
        }
        await this.FETCH_UPLOADS_LOGS({
          page: this.filter.page = 1,
          page_size: this.filter.page_size,
          ...this.dateFilter,
        })
      }
    },

    openModal() {
      this.$bvModal.show('importModal')
    },

    downloadFile(file) {
      const link = document.createElement('a')
      link.href = file
      link.setAttribute('download', 'file.xls')
      document.body.appendChild(link)
      link.click()
    },

    changePage(page) {
      this.filter.page = page
      this.fetchActionList()
    },

    searchConstructors() {
      this.filter.page = 1
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.FETCH_UPLOADS_LOGS({
          created_date_from: this.dateFilter.created_date_from ? this.dateFilter.created_date_from : null,
          created_date_to: this.dateFilter.created_date_to ? this.dateFilter.created_date_to : null,
          ...this.filter,
        })
      }, 600)
    },

    async fetchActionList() {
      try {
        this.loading = true
        await this.FETCH_UPLOADS_LOGS({
          created_date_from: this.dateFilter.created_date_from ? this.dateFilter.created_date_from : null,
          created_date_to: this.dateFilter.created_date_to ? this.dateFilter.created_date_to : null,
          ...this.filter,
        })
      } catch (error) {
        await this.$_errorToast()
      }
      this.loading = false
    },
  },
}
</script>
