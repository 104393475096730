<template>
  <BModal
    id="importModal"
    centered
    ok-title="Сохранить"
    cancel-title="Отмена"
    cancel-variant="danger"
    title="Импорт реестров"
    no-close-on-backdrop
    size="lg"
    class="list-items-views-edit"
    @hidden="onHideModal"
  >
    <template slot="modal-footer">
      <b-button
        variant="danger"
        @click="$bvModal.hide('importModal')"
      >
        Отмена
      </b-button>
      <b-button
        variant="primary"
        :disabled="loader"
        @click.prevent="checkSelectedCompany"
      >
        <div class="align-items-center d-flex">
          <b-spinner
            v-if="loader"
            style="height: 20px;width: 20px"
            class="mr-1"
          />
          Сохранить
        </div>
      </b-button>
    </template>
    <ValidationObserver ref="importValidation">
      <b-row>
        <b-col cols="12">
          <ValidationProvider
            #default="{ errors }"
            name="Файл"
            rules="required"
          >
            <BFormFile
              v-model="formData.file"
              accept=".xls"
              placeholder="Файл не выбран"
              drop-placeholder="Перетащите файлы сюда"
              browse-text="Выбрать"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <v-select
            v-if="formData.type.match(/PAYMENT/)"
            v-model="formData.CLAIMER_CRM_COMPANY_ID"
            label="COMPANY_NAME"
            :options="debtCompanyList.results"
            placeholder="Взыскатель"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <!--          v-if="formData.type.match(/DEBT|LEGAL|DEBT_DK|PAYMENT/)"-->
          <ValidationProvider
            v-else
            #default="{ errors }"
            name="Взыскатель"
            rules="required"
          >
            <v-select
              v-model="formData.CLAIMER_CRM_COMPANY_ID"
              label="COMPANY_NAME"
              :options="debtCompanyList.results"
              placeholder="Взыскатель"
            >
              <template #no-options>
                К сожалению, нет подходящих вариантов
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <div class="d-flex pt-1">
            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              class="mr-1"
              value="DEBT"
            >
              Дела
            </b-form-radio>

            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              value="PAYMENT"
              @input="formData.CLAIMER_CRM_COMPANY_ID = null"
            >
              Платежи
            </b-form-radio>

            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              class="ml-2 mr-1"
              value="DEBT_DK"
            >
              Дела ДК
            </b-form-radio>
            <b-form-radio
              v-model="formData.type"
              name="some-radios"
              value="LEGAL"
            >
              Юр лица
            </b-form-radio>

            <b-form-checkbox
              v-model="formData.is_sk"
              class="ml-2"
              name="check-button"
              :checked="false"
              inline
            >
              Работа в СК
            </b-form-checkbox>

            <b-form-checkbox
              v-model="formData.updated_debt"
              class="ml-2"
              name="check-button"
              :checked="false"
              inline
            >
              Ежедневный отчет
            </b-form-checkbox>
          </div>

          <b-form-checkbox
            v-model="formData.debt_exclude"
            class="mt-2"
            name="check-button"
            :checked="false"
            inline
          >
            Отозван
          </b-form-checkbox>
        </b-col>
      </b-row>
    </ValidationObserver>
    <verifyCompanyVue
      :company="companyForCheck"
      @okClicked="uploadFile"
    />
  </BModal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormFile, BFormRadio, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import VSelect from 'vue-select'
import { required } from '@validations'
import verifyCompanyVue from './verifyCompany.vue'

export default {
  components: {
    BFormCheckbox,
    BModal,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BFormRadio,
    BSpinner,
    VSelect,
    verifyCompanyVue,
  },
  data() {
    return {
      loader: false,
      formData: {
        file: null,
        type: 'DEBT',
        CLAIMER_CRM_COMPANY_ID: null,
        is_sk: false,
        updated_debt: false,
        debt_exclude: false,
      },
      companyForCheck: '',
      required,
    }
  },
  computed: {
    ...mapState('cases', ['debtCompanyList']),
  },
  mounted() {
    this.FETCH_SK_DEBT_COMPANY_LIST({ page_size: 1000 })
  },
  methods: {
    ...mapActions('importRegistries', ['CREATE_UPLOADS_LOGS']),
    ...mapActions('cases', ['FETCH_SK_DEBT_COMPANY_LIST']),

    async checkSelectedCompany() {
      const success = await this.$refs.importValidation.validate()
      if (!success) return
      if (this.formData.type === 'DEBT') {
        this.companyForCheck = this.formData.CLAIMER_CRM_COMPANY_ID.COMPANY_NAME
        this.$nextTick(() => {
          this.$bvModal.show('verifyCompany')
        })
      } else {
        await this.uploadFile()
      }
    },

    async uploadFile() {
      try {
        this.loader = true
        const fd = new FormData()
        fd.append('file', this.formData.file)
        fd.append('type', this.formData.type)
        fd.append('is_sk', this.formData.is_sk)
        fd.append('debt_exclude', this.formData.debt_exclude)
        fd.append('updated_debt', this.formData.updated_debt)
        if (this.formData.type.match(/DEBT|LEGAL|DEBT_DK|PAYMENT/)) fd.append('CLAIMER_CRM_COMPANY_ID', this.formData.CLAIMER_CRM_COMPANY_ID.COMPANY_ID)
        await this.CREATE_UPLOADS_LOGS(fd)
        this.$emit('refresh')
        await this.$_okToast()
        this.$bvModal.hide('verifyCompany')
        this.$bvModal.hide('importModal')
      } catch (error) {
        this.loader = false
        await this.$_errorToast()
      }
      this.loader = false
    },

    onHideModal() {
      this.formData = {
        file: null,
        type: 'DEBT',
        CLAIMER_CRM_COMPANY_ID: null,
        is_sk: false,
        updated_debt: false,
        debt_exclude: false,
      }
    },
  },
}
</script>
